// src/actions/authActions.js

import axios from 'axios';
import api, { setAuthToken } from '../api/api'; // Removed updateBaseURL

// Action Types
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REFRESH_TOKEN_FAILURE,
  REFRESH_TOKEN_SUCCESS
} from './actionTypes';

// Action Creators
export const loginSuccess = (email, id, access, refresh, first_name, last_name, groups, company, job_title) => ({
  type: LOGIN_SUCCESS,
  payload: { email, id, access, refresh, first_name, last_name, groups, company, job_title },
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('tenant_domain'); // Remove tenant domain
  setAuthToken(false);

  return {
    type: LOGOUT,
  };
};

// Async action for logging in
export const loginUser = (credentials) => async (dispatch) => {
  try {
    const response = await api.post('token/', credentials);

    if (response.status === 200) {
      const data = response.data;

      if (data.redirect) {
        // Redirect is needed
        return data;
      } else {
        // Normal login flow
        const { tokens, email, id, first_name, last_name, groups, company, job_title, tenant_domain } = data;
        const { access, refresh } = tokens;

        // Store tokens and tenant domain
        localStorage.setItem('access_token', access);
        localStorage.setItem('refresh_token', refresh);
        localStorage.setItem('tenant_domain', tenant_domain);  // Correctly store tenant domain here

        setAuthToken(access);

        // No need to update baseURL

        // Dispatch success action
        dispatch(loginSuccess(email, id, access, refresh, first_name, last_name, groups, company, job_title));

        return data;
      }
    } else {
      dispatch(loginFailure('Authentication failed.'));
      throw new Error('Authentication failed.');
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred.';
    dispatch(loginFailure(errorMessage));
    throw error;
  }
};

// Async action for logging in with redirect code
export const loginUserWithRedirectCode = (redirectCode) => async (dispatch) => {
  try {
    const response = await api.post('token-exchange/', { redirect_code: redirectCode });

    if (response.status === 200) {
      const data = response.data;
      const { tokens, email, id, first_name, last_name, groups, company, job_title, tenant_domain } = data;
      const { access, refresh } = tokens;

      // Store tokens and tenant domain
      localStorage.setItem('access_token', access);
      localStorage.setItem('refresh_token', refresh);
      localStorage.setItem('tenant_domain', tenant_domain);  // Correctly store tenant domain here

      setAuthToken(access);

      // No need to update baseURL

      // Dispatch success action
      dispatch(loginSuccess(email, id, access, refresh, first_name, last_name, groups, company, job_title));

      return data;
    } else {
      dispatch(loginFailure('Authentication failed.'));
      throw new Error('Authentication failed.');
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred.';
    dispatch(loginFailure(errorMessage));
    throw error;
  }
};

// Async action to refresh token
// src/actions/authActions.js

export const refreshToken = () => async (dispatch, getState) => {
  const refresh = localStorage.getItem('refresh_token') || getState().auth.refresh;

  if (!refresh) {
    console.log("No refresh token available");
    dispatch(logout());
    return Promise.reject('No refresh token available');
  }

  try {
    const response = await api.post('/token/refresh/', { refresh });
    const newAccessToken = response.data.access;

    // Retrieve the current state to get the user ID and other details
    const currentState = getState().auth;

    localStorage.setItem('access_token', newAccessToken);

    // Dispatch the REFRESH_TOKEN_SUCCESS action with the new token and existing user info
    dispatch({
      type: REFRESH_TOKEN_SUCCESS,
      payload: {
        access: newAccessToken,
        id: currentState.id, // Maintain the current user's ID
        email: currentState.email,
        first_name: currentState.first_name,
        last_name: currentState.last_name,
        groups: currentState.groups,
      },
    });

    return newAccessToken;
  } catch (error) {
    console.log("Failed to refresh token:", error);

    // Dispatch a simplified error object to keep Redux state serializable
    dispatch({
      type: REFRESH_TOKEN_FAILURE,
      payload: {
        message: error.response?.data?.detail || error.message,
        code: error.code || null,
      },
    });

    dispatch(logout());
    return Promise.reject(error);
  }
};

