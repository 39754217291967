import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFormTemplates, fetchProjects, submitForm, updateForm } from '../../apislice';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  Modal,
  CircularProgress,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Drawer,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const FormEditor = ({ form, onClose }) => {
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedProject, setSelectedProject] = useState('');
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true); // To toggle template selection
  const templates = useSelector(state => state.api.formTemplates.data);
  const projects = useSelector(state => state.api.projects.data);
  const getCurrentUserId = useSelector(state => state.auth.id);
  const [uploadedFiles, setUploadedFiles] = useState({});

  // Fetch templates and projects when the component mounts
  useEffect(() => {
    dispatch(fetchFormTemplates());
    dispatch(fetchProjects());
  }, [dispatch]);

  // Pre-load form data if editing an existing form
  useEffect(() => {
    if (form && templates.length > 0) {
      const template = templates.find(t => t.id === form.template);
      if (!template) return;

      let selectedVersion = null;
      if (form.template_version) {
        selectedVersion = template.versions.find(v => v.id === form.template_version);
      }

      if (!selectedVersion) {
        selectedVersion = getLatestPublishedVersion(template);
      }

      if (selectedVersion) {
        setSelectedTemplate(selectedVersion);
        const alignedFormData = alignFormDataWithTemplateFields(selectedVersion.fields, form.data);
        setFormData(alignedFormData);
      }

      setSelectedProject(form.project);
    }
  }, [form, templates]);

  // Helper to find the latest published version of the template
  const getLatestPublishedVersion = (template) => {
    return template.versions
      .filter(v => !v.is_draft && v.visible)
      .sort((a, b) => b.version_number - a.version_number)[0];
  };

  const visibleTemplates = templates.filter(template => {
    const visibleVersion = template.versions.some(v => !v.is_draft && v.visible);
    return template.visible && visibleVersion;
  });

  // Function to get template name by version template ID
  const getTemplateNameByVersion = (version, templates) => {
    const matchingTemplate = templates.find(template => template.id === version.template);
    return matchingTemplate ? matchingTemplate.name : 'Template not found';
  };

  // Align form data with template fields for editing
  const alignFormDataWithTemplateFields = (fields, data) => {
    const alignedData = {};
    
    if (data === null) {
      // If data is null, set all fields to empty string or default value
      fields.forEach(field => {
        alignedData[field.id] = ''; // Set default value
      });
    } else {
      // If data exists, align it with the template fields
      fields.forEach(field => {
        alignedData[field.id] = data[field.id] || ''; // Align field id with data, defaulting to empty string if missing
      });
    }
    
    return alignedData;
  };
  const handleFileChange = (fieldId, files) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [fieldId]: files,
    }));
  };
  

  const renderFormField = (field) => {
    // Common properties without the 'onChange' handler
    const commonProps = {
      key: field.id,
      label: field.label || '',
      required: field.required || false,
      value: formData[field.id] || '',
      fullWidth: true,
      margin: 'normal',
      size: 'small',
      InputProps: { style: { fontSize: field.font_size || 14 } },
    };
  
    switch (field.item_type) {
      case 'textInput':
        return (
          <TextField
            {...commonProps}
            onChange={(e) => handleFormChange({ [field.id]: e.target.value })}
          />
        );
  
      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={formData[field.id] || false}
                onChange={(e) => handleFormChange({ [field.id]: e.target.checked })}
                size="small"
              />
            }
            label={field.label}
            sx={{ mb: 2 }}
          />
        );
  
      case 'dropdown':
        return (
          <FormControl fullWidth sx={{ mb: 2 }} key={field.id} size="small">
            <InputLabel>{field.label || 'Select'}</InputLabel>
            <Select
              value={formData[field.id] || ''}
              onChange={(e) => handleFormChange({ [field.id]: e.target.value })}
            >
              {field.options?.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
  
      case 'dateInput':
        return (
          <FormControl fullWidth key={field.id} sx={{ mb: 2 }} size="small">
            <TextField
              {...commonProps}
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleFormChange({ [field.id]: e.target.value })}
            />
          </FormControl>
        );
  
      case 'heading':
        return (
          <Typography
            key={field.id}
            variant={field.size || 'h6'}
            gutterBottom
            align={field.alignment || 'left'}
            sx={{ mb: 2 }}
          >
            {field.label}
          </Typography>
        );
  
        case 'table':
          // Ensure field.columns is defined and has a length before using it
          if (field.columns && field.columns.length && !formData[field.id]?.rows) {
            // Initialize rows with one empty row if not set
            handleFormChange({
              [field.id]: {
                rows: [Array(field.columns.length).fill('')], // Initialize with one empty row
              },
            });
          }
          
          const handleAddRow = () => {
            const updatedRows = [...(formData[field.id]?.rows || [])];
            const emptyRow = Array(field.columns.length).fill('');
            updatedRows.push(emptyRow);
        
            handleFormChange({
              [field.id]: { ...formData[field.id], rows: updatedRows },
            });
          };
        
          const handleCellChange = (rowIndex, colIndex, value) => {
            const updatedRows = [...(formData[field.id]?.rows || [])];
            const updatedRow = [...updatedRows[rowIndex]];
        
            updatedRow[colIndex] = value;
            updatedRows[rowIndex] = updatedRow;
        
            handleFormChange({
              [field.id]: { ...formData[field.id], rows: updatedRows },
            });
          };
        
          return (
            <Box key={field.id} sx={{ mb: 2, overflowX: 'auto' }}>
              <Typography variant="h6" gutterBottom>
                {field.label}
              </Typography>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    {field.columns?.map((col, colIndex) => (
                      <th
                        key={colIndex}
                        style={{
                          border: '1px solid #ddd',
                          padding: '8px',
                          textAlign: 'left',
                        }}
                      >
                        {col.label}
                      </th>
                    )) || <th>No columns available</th>}
                  </tr>
                </thead>
                <tbody>
                  {formData[field.id]?.rows?.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, colIndex) => (
                        <td
                          key={colIndex}
                          style={{ border: '1px solid #ddd', padding: '8px' }}
                        >
                          <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={cell || ''}
                            onChange={(e) =>
                              handleCellChange(rowIndex, colIndex, e.target.value)
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ mt: 2 }}
                onClick={handleAddRow}
              >
                Add Row
              </Button>
            </Box>
          );
  
        case 'fileUpload':
          // Display file upload input or download links if files are already uploaded
          return (
            <Box key={field.id} sx={{ mb: 2 }}>
              <Typography variant="body1">{field.label}</Typography>
        
              {/* If editing and files are already uploaded, show them as download links */}
              {form?.files?.length > 0 ? (
                <Box sx={{ mt: 1 }}>
                  <Typography variant="h6">Uploaded Files:</Typography>
                  <ul>
                    {form.files.map((file, index) => (
                      <li key={index}>
                        <a href={file.file} target="_blank" rel="noopener noreferrer">
                          {file.file.split('/').pop()} {/* Extract and show the file name */}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Box>
              ) : (
                uploadedFiles[field.id]?.length > 0 && (
                  <Box sx={{ mt: 1 }}>
                    {uploadedFiles[field.id].map((file, index) => (
                      <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Typography variant="body2">
                          Selected file: {file.name}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            const updatedFiles = uploadedFiles[field.id].filter((_, i) => i !== index);
                            handleFileChange(field.id, updatedFiles); // Remove file from selection
                          }}
                          color="secondary"
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                )
              )}
        
              {/* Always show file input for uploading new files */}
              <Box
                sx={{
                  border: '1px dashed #ccc',
                  p: 2,
                  borderRadius: '4px',
                  textAlign: 'center',
                  cursor: 'pointer',
                  backgroundColor: '#f9f9f9',
                }}
                onDrop={(event) => {
                  event.preventDefault();
                  const files = Array.from(event.dataTransfer.files);
                  if (files.length > 0) {
                    handleFileChange(field.id, files); // Handle multiple files dropped
                  }
                }}
                onDragOver={(event) => event.preventDefault()}
              >
                <input
                  type="file"
                  multiple // Allow multiple files
                  onChange={(event) => {
                    const files = Array.from(event.target.files);
                    if (files.length > 0) {
                      handleFileChange(field.id, files); // Handle multiple files selected
                    }
                  }}
                  style={{ display: 'none' }}
                  id={`file-upload-${field.id}`}
                />
                <label htmlFor={`file-upload-${field.id}`}>
                  <Button variant="contained" component="span" sx={{ mt: 1 }}>
                    Upload Files
                  </Button>
                </label>
              </Box>
            </Box>
          );

      default:
        return null;
    }
  };
  
  

  // Handle template selection
  const handleTemplateSelect = (template) => {
    const latestVersion = getLatestPublishedVersion(template);
    setSelectedTemplate(latestVersion);
    setFormData({}); // Clear form data when a new template is selected
    setSidebarOpen(false); // Close sidebar when template is selected
  };

  // Handle form changes
  const handleFormChange = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  // Handle project selection
  const handleProjectSelect = (event) => {
    setSelectedProject(event.target.value);
  };

  const handleSubmit = (isDraft = false) => {
    setLoading(true);
  
    const formDataToSend = new FormData();
    formDataToSend.append('project', selectedProject);
    formDataToSend.append('template_version', selectedTemplate.id);
    formDataToSend.append('template', selectedTemplate.template);
    formDataToSend.append('created_by', getCurrentUserId);
    formDataToSend.append('updated_by', getCurrentUserId);
    formDataToSend.append('status', isDraft ? 'draft' : 'submitted');
  
    // Log the files in the state
    console.log('Files from state before appending to FormData:', uploadedFiles);
  
    // Iterate over the uploadedFiles object
    Object.keys(uploadedFiles).forEach((key) => {
      const filesArray = uploadedFiles[key]; // Get the array of files
      filesArray.forEach((file) => {
        formDataToSend.append('uploaded_files', file); // Append each file to FormData
        console.log('Appended file:', file.name);
      });
    });

      // Handle other form data (serialize it as JSON if it's complex like tables)
    formDataToSend.append('data', JSON.stringify(formData));
  
    // Log the complete FormData
    for (let pair of formDataToSend.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }
  
    // Send the formDataToSend (e.g., via Axios)
    const submitAction = form?.id
      ? dispatch(updateForm({ formId: form.id, form: formDataToSend }))
      : dispatch(submitForm({ form: formDataToSend }));
  
    submitAction
      .then((response) => {
        console.log('Form submission successful:', response);
        alert(`Form ${isDraft ? 'saved as draft' : 'submitted'} successfully!`);
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        console.error('Form submission error:', error);
        alert(
          `Failed to ${isDraft ? 'save draft' : 'submit form'}. Please check the console for details.`
        );
        setLoading(false);
      });
  };
  
  
  
  
  
  

  return (
    <Modal open={true} onClose={onClose} aria-labelledby="form-editor-modal" sx={{ display: 'flex' }}>
      <Box sx={modalStyle}>
        {/* Only show the drawer with template list and back button if not in edit mode */}
        {!form && (
          <Drawer
            variant="persistent"
            open={sidebarOpen}
            sx={{
              width: '300px',
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: '300px',
                boxSizing: 'border-box',
              },
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography variant="h6">Select a Template</Typography>
              <List>
                {visibleTemplates.map(template => {
                  const latestVersion = getLatestPublishedVersion(template);
                  return (
                    <ListItem
                      button
                      key={template.id}
                      onClick={() => handleTemplateSelect(template)}
                    >
                      <ListItemText primary={`${template.name} (Version ${latestVersion.version_number})`} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Drawer>
        )}

        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', transition: 'width 0.3s ease' }}>
            <IconButton onClick={onClose} sx={{ position: 'absolute', right: 16, top: 16 }}>
                <CloseIcon />
            </IconButton>
          
            {/* Only show the back arrow if not in edit mode */}
            {!form && (
              <IconButton onClick={() => setSidebarOpen(true)} sx={{ position: 'absolute', left: 16, top: 16 }}>
                <ArrowBackIcon />
              </IconButton>
            )}

            <Typography variant="h4" gutterBottom align="center">
                {selectedTemplate
                    ? `Form: ${getTemplateNameByVersion(selectedTemplate, templates)} (Version ${selectedTemplate.version_number})`
                    : 'New Form'}
            </Typography>


          {/* Check if the template is visible */}
          {selectedTemplate && selectedTemplate.visible ? (
            <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, pr: 2 }}>
              {/* Project Selector */}
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel id="project-select-label">Select Project</InputLabel>
                <Select
                  labelId="project-select-label"
                  id="project-select"
                  value={selectedProject}
                  onChange={handleProjectSelect}
                  label="Select Project"
                >
                  <MenuItem value="">
                    <em>Select a project</em>
                  </MenuItem>
                  {projects.map(project => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.project_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Form Fields */}
              {selectedTemplate && selectedTemplate.fields.map(field => renderFormField(field))}

            </Box>
          ) : (
            <Typography variant="h6" color="error" align="center">
                Select a template to begin
            </Typography>
          )}

          {selectedTemplate && selectedTemplate.visible && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2, borderTop: '1px solid #e0e0e0' }}>
              {/* Only display 'Save Draft' if the form is not already submitted */}
              {form?.status !== 'submitted' && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSubmit(true)}  // Save as draft
                  disabled={loading}
                  startIcon={loading && <CircularProgress size={20} />}
                >
                  {form?.id ? 'Save Draft' : 'Save as Draft'}
                </Button>
              )}
              
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit(false)}  // Submit or Update depending on status
                disabled={loading}
                startIcon={loading && <CircularProgress size={20} />}
              >
                {form?.status === 'draft' ? 'Submit' : form?.id ? 'Update' : 'Submit'}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  display: 'flex',
  flexDirection: 'row',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  width: '80%',
  height: '80%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export default FormEditor;
