import React from 'react';
import { Routes, Route, BrowserRouter} from 'react-router-dom';
import Container from 'react-bootstrap/Container';


import NavbarDark from './components/Navbar';
import ProjectDisplay from './components/ProjectDisplay';
import Login from './components/login';
import ProtectedRoute from './components/ProtectedRoute'; 
import { connect } from 'react-redux';

import ApprovalsGrid from './components/Approvals/Approvals';


import CompaniesHome from './components/Companies/Companies';
import HomeMui from './components/HomeMui'
import CompaniesManage from './components/Companies/CompaniesManage';
import ForgotPassword from './components/Login/forgotPassword';
import UsersHome from './components/users/Users';

import ProjectsHome from './components/Projects/Projects';

import ResetPassword from './components/Login/resetPassword';

import StockItemsHome from './components/Stock/Stock';
import FormBuilderComponent from './components/Formbuilder/formBuilder';
// import FormFiller from './components/Formbuilder/Form';
import FormHomePage from './components/Forms/FormHomePage';
import FormEditor from './components/Formbuilder/FormEditor';

import LandingPage from './components/Landing';
import RedirectLogin from './components/RedirectLogin';


const App = ({ isAuthenticated }) => {
  return (
    <BrowserRouter>
     
      <NavbarDark />
        <Container fluid >
          {/* {isAuthenticated && <Sidebar />} Render Sidebar conditionally */}


          <Container fluid>
            <Routes>
              <Route path="/landing" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/redirect-login" element={<RedirectLogin />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/password-reset-confirm/:uid/:token" element={<ResetPassword />} />
              <Route  path="/" element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>

                <Route index element={<HomeMui/>} />
                <Route path="/projects/*" element={<ProjectsHome />} />
                <Route path="/projects2/*" element={<ProjectDisplay />} />
                <Route path='/approvals/' element={<ApprovalsGrid />} />
                <Route path='/companies/' element={<CompaniesHome />} />
                <Route path='/home' element={<HomeMui />} />
                <Route path='/companies/manage/' element={<CompaniesManage />} />
                <Route path='/users' element={<UsersHome />} />
                <Route path='/stock' element={<StockItemsHome />} />
                <Route path='/formbuilder' element={<FormBuilderComponent />} />
                {/* <Route path='/formfiller' element={<FormFiller />} /> */}
                <Route path='/forms' element={<FormHomePage />} />
                {/* <Route path="*" component={NotFoundPage} /> */}

              </Route>
            </Routes>
          </Container>
        </Container>

    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  email: state.auth.email,
  
});

export default connect(mapStateToProps)(App);
