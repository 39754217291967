// src/components/Navbar.js
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../App.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';  
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/authActions';
import axios from 'axios';
import ProtectedRoute from './ProtectedRoute';
import { NavItem } from 'react-bootstrap';
import { useUserGroup } from './users/userGroup';



function UserGroupChecker({children}) {
  const isUserGroupApproved = useUserGroup(['Admin', 'Manager', 'Director']);
  return isUserGroupApproved ? children : null;
}


function NavbarDark() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const isUserGroupApproved = useUserGroup(['Admin', 'Manager', 'Director']);

  // const isApproved = isAuthenticated && isUserGroupApproved;



  const handleLogout = () => {
    localStorage.removeItem('access_token'); // Remove the correct item name
    localStorage.removeItem('refresh_token'); // Remove the refresh token if needed
    delete axios.defaults.headers.common['Authorization'];
    dispatch( logout());
  }

  return (
    <Navbar bg="dark" data-bs-theme="dark" className='container-fluid'>
      <Container fluid>
        <Navbar.Brand href="/">SiteSinc</Navbar.Brand>
        <Nav className="me-auto">

          {isAuthenticated && (
            <>
              <Nav.Link as={NavLink} to="/">Home</Nav.Link>
              
              <UserGroupChecker>
                <Nav.Link as={NavLink} to="/projects/">Projects</Nav.Link>
                <Nav.Link as={NavLink} to="/companies/">Companies</Nav.Link>

              </UserGroupChecker>
              <UserGroupChecker>
                {/* <Nav.Link href="/approvals/">Approvals</Nav.Link> */}
                <Nav.Link as={NavLink} to="/users">Users</Nav.Link>
                <Nav.Link as={NavLink} to="/stock">Stock</Nav.Link>
                <Nav.Link as={NavLink} to="/forms">Forms</Nav.Link>
                {/* <Nav.Link href='/forms'>Forms</Nav.Link> */}
                <Nav.Link as={NavLink} to="/formbuilder">Form Builder</Nav.Link>
              </UserGroupChecker>
              <UserGroupChecker>
                
              </UserGroupChecker>

            </>
          )}
        </Nav>
        <Nav>
          <Navbar.Collapse className="justify-content-end" >
            {/* {isAuthenticated ? (
            <NavItem>
              <i class="bi bi-exclamation-triangle warning-triangle"></i>
            </NavItem>
            ) : (
              <NavItem>
                <i class="bi bi-person-circle"></i>
              </NavItem>
            )} */}

            {isAuthenticated ? (
            <NavDropdown title={`${user.first_name} ${user.last_name}`} id="basic-nav-dropdown">
              <NavDropdown.Item  onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>  
            ) : (
              <Nav.Link href="/login">Login</Nav.Link>
            )}
          </Navbar.Collapse>
        </Nav>

      </Container>
    </Navbar>

  );
}

export default NavbarDark;
