import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FileUploadItem = ({ item, handleChange }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Get the selected files
    if (files.length > 0) {
      const newFiles = [...uploadedFiles, ...files];
      setUploadedFiles(newFiles);
      handleChange(item.id, newFiles); // Pass all files to the handler
    }
  };

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
      const files = Array.from(event.dataTransfer.files); // Get the dropped files
      if (files.length > 0) {
        const newFiles = [...uploadedFiles, ...files];
        setUploadedFiles(newFiles);
        handleChange(item.id, newFiles); // Pass all files to the handler
      }
    },
    [handleChange, item.id, uploadedFiles]
  );

  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default to allow drop
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1); // Remove the file from the list
    setUploadedFiles(updatedFiles);
    handleChange(item.id, updatedFiles); // Update the parent with the modified list
  };

  return (
    <Box
      sx={{
        border: '1px dashed #ccc',
        p: 2,
        borderRadius: '4px',
        textAlign: 'center',
        mb: 2,
        cursor: 'pointer',
        backgroundColor: '#f9f9f9',
      }}
      onDrop={handleDrop} // Handle file drop
      onDragOver={handleDragOver} // Handle drag over
    >
      <Typography variant="body1">{item.label}</Typography>
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the default file input
        id={`file-upload-${item.id}`}
      />
      <label htmlFor={`file-upload-${item.id}`}>
        <Button variant="contained" component="span" sx={{ mt: 1 }}>
          {uploadedFiles.length > 0 ? 'Add More Files' : 'Upload Files'}
        </Button>
      </label>

      {uploadedFiles.length > 0 && (
        <List sx={{ mt: 2 }}>
          {uploadedFiles.map((file, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemoveFile(index)}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <ListItemText primary={file.name} />
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default FileUploadItem;
